


































































import { Component, Vue } from "vue-property-decorator";
import TopWord from "@/components/HomeWordTop.vue";
import { getcustomizationImgList,getformSettings,postformsave } from "@/api/home";
@Component({
  components: { TopWord },
})
export default class Customized extends Vue {
  formDto={
    formSettingId:"",
    name:"",
    phone:"",
    requirement:'',
    wechatId:''
  }
  customizationImg = [];
  pageDetail = [];
  formName="";

  created() {
    getcustomizationImgList().then((res) => {
      this.customizationImg = res.data.customizationImg.split(",");
      this.pageDetail = res.data.pageDetail.split(",");
    });
    getformSettings().then(res=>{
      this.formName=res.data.formName
      this.formDto.formSettingId=res.data.id
    })
  }
  save(){
    console.log(!/^1(3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[1,8,9])\d{8}$/.test(this.formDto.phone));
     if (!this.formDto.name) {
    this.$message.error("称呼姓名不能为空");
    return;
  }
  if (!this.formDto.wechatId&&!this.formDto.phone) {
    this.$message.error("请至少填写微信/QQ与手机号码中的一项");
    return;
  }
  if (!/^1(3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[1,8,9])\d{8}$/.test(this.formDto.phone)&&this.formDto.phone) {
    this.$message.error("请填写正确手机号");
    return;
  }
  if (!this.formDto.requirement) {
    this.$message.error("需求内容不能为空");
    return;
  }
  postformsave(this.formDto).then(()=>{
    this.$message.success("保存成功");
  }).catch(err=>{
    this.$message.error(err);
  });
    
  }
}
